<template>
    <OPropertiesGrid :dataObject="props.dataObject" hideOkCancel>
        <OPropertiesGroup :caption="$t('Properties')">
            <OPropertiesForm :row="props.dataObject.current" viewName="aviw_Arena_ExternalDistributions" hideLoadingIndicator :key="refreshPropGrid">
                <template v-slot:noProperties><span></span></template>
                <template #default="{properties, editor, renderer}">
                    <div v-for="property in properties">
                        <OPropertiesItem v-if="propertyIsRequired(property.name)"
                        :caption="property.caption ?? property.name"
                        :title="property.title"
                        :fieldName="property.name"
                        :required="true">
                            <OPropertiesEditor 
                                :row="props.dataObject.current.propertiesRows[property.name]" 
                                class="border-0 w-100" @blur="saveDataObject"
                                wrapperClass="w-100"
                                v-model="props.dataObject.current.properties[property.name]" :config="property" :disabled="disabledDO"/>
                        </OPropertiesItem>
                        {{ AttachPropListener(props.dataObject._propertiesData._propertiesDataObject) }}
                    </div>
                    <div v-for="property in properties">
                        <OPropertiesItem v-if="!propertyIsRequired(property.name)"
                        :caption="property.caption ?? property.name"
                        :title="property.Title"
                        :fieldName="property.name">
                            <OPropertiesEditor 
                                :row="props.dataObject.current.propertiesRows[property.name]" 
                                class="border-0 w-100" @blur="saveDataObject"
                                wrapperClass="w-100"
                                v-model="props.dataObject.current.properties[property.name]" :config="property" :disabled="disabledDO"/>
                        </OPropertiesItem>
                        {{ AttachPropListener(props.dataObject._propertiesData._propertiesDataObject) }}
                    </div>
                </template>
            </OPropertiesForm>
        </OPropertiesGroup>
        <ODataLookup  
            :data-object="local_dsPropertiesLkp"
            :height="400"
            :bind="sel => handleAddProperty(sel)"
            :noClear="true">
                <template #target="scope" @click="scope.open">
                    <h2 class="mb-0 mt-2 ms-1">
                        <button class="btn btn-sm btn-link py-0 mb-1 ps-0"  :ref="scope.target" style="font-size: 16px;"><i class="bi bi-plus-circle me-1"></i>{{$t("Add Property")}}</button>
                    </h2>
                </template>
                <OColumn name="PropertyName" width="400"></OColumn>
        </ODataLookup>
    </OPropertiesGrid>
</template>
<script setup>
import { getOrCreateDataObject } from 'o365-dataobject';
import { ref, computed } from 'vue';
import { OPropertiesGrid, OPropertiesItem, OPropertiesGroup }  from 'o365-data-components';
import { OPropertiesForm, OPropertiesEditor } from 'o365-data-properties';

const props = defineProps({
    dataObject: Object
})

const emit = defineEmits(["propertySaved"]);    
async function saveDataObject(){
    await props.dataObject.save(); 
    await local_ExtDistProperties.save(); //needed when creating from deliverables?
    await local_dsErrors.load();
    emit("propertySaved");
}

const handleAddProperty = (pSel) => {
    local_ExtDistProperties.createNew({PropertyName:pSel.PropertyName});
    local_ExtDistProperties.save().then(() => props.dataObject.load());
}

const AttachPropListener = (pT) => {
    if(!propListenerAttached){
        props.dataObject._propertiesData._propertiesDataObject.on('AfterSave', () => {
            props.dataObject.refreshRow()
        })
        propListenerAttached = true;
    }
}

const propertyIsRequired = (pProp) => {
    if(requiredFields.value[`${pProp}`])
        return true;
    else
        return false;
}

const requiredFields = ref("");
const refreshPropGrid = ref(0)
let propListenerAttached = false

const local_dsErrors = getOrCreateDataObject({
    id: 'local_dsErrors',
    viewName: 'atbv_Arena_ExternalDistributionsValidationErrors',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "ExternalDistribution_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "Error", type: "string"},
        {name: "FieldName", type: "string" }]
}),

local_dsPropertiesLkp = getOrCreateDataObject({
    id: 'local_dsPropertiesLkp',
    viewName: 'aviw_Arena_ExternalDistributionsAvailableProperties',
    appId: props.dataObject.appId,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    fields:
        [{name: "Name", type: "string"},
        {name: "PropertyName", type: "string"},
        {name: "PropertyNameTranslated", type: "string"},
        { name: "DataType", type: "string" },
        { name: "Description", type: "date" },
        { name: "Title", type: "datetime" },]
}),

local_ExtDistProperties = getOrCreateDataObject({
    id: 'local_ExtDistProperties',
    viewName: 'aviw_Arena_ExternalDistributionsProperties2',
    appId: props.dataObject.appId,
    allowInsert:true,
    allowUpdate:true,
    allowDelete:true,
    maxRecords: -1,
    whereClause: "",
    loadRecents: true,
    distinctRows: true,
    masterDataObject_ID: props.dataObject.id,
    masterDetailDefinition: [{
        detailField: "ExternalDistribution_ID",
        masterField: "ID",
        operator: "equals"
    }],
    fields:
        [{name: "PropertyName", type: "string"},
        { name: "Value", type: "string" },
        { name: "DateValue", type: "date" },
        { name: "DateTimeValue", type: "datetime" },
        { name: "IntValue", type: "number" },
        { name: "Required", type: "boolean" },]
})

const disabledDO = computed(() => {
    return  props.dataObject.current.Sent !== null;
});

const getRequiredFields = () => {
    requiredFields.value = {}

    local_ExtDistProperties.data.map((row)=>{
        if(row.Required){
            requiredFields.value[row.PropertyName] = true;
        }
    })
}

local_dsErrors.on('DataLoaded', () => {
    getRequiredFields();
})

local_ExtDistProperties.on('AfterSave',() => {
    // local_dsErrors.load().then(()=>{
        getRequiredFields();
    // });
})

local_ExtDistProperties.load().then(() => {
    local_dsErrors.load();
})


</script>

<style scoped>
.spinner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.spinner-border {
  width: 5rem;
  height: 5rem;
}

.overlay__inner{
    display:none;
}

</style>